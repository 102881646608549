import React, {useState, useRef} from 'react'


const Form = (props) => {
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbxGNigv8phBJQEII0UlslRDOjo9r7h-aBXkslwCCd9k7ddydwNGT3jcSXH76WOf2JcczA/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),

  }).then(res => {
          //console.log("SUCCESSFULLY SUBMITTED")
          window.location.assign('/devis/contact/merci/')
          setLoading(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <div id="sideform" className="mt-12 flex flex-col flex-grow rounded-lg my-8">
        <div className="flex flex-col flex-grow bg-gray-100 rounded-lg py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        
            <form 
                className="gap-y-6 w-full" 
                name="google-sheet"
                ref={formRef} onSubmit={handleSubmit}
            >
                <div className="pb-4">
                    <h3 className="text-2xl font-bold text-center pb-4">
                    Demandez un dépannage
                    </h3>
                    <label htmlFor="nom" className="sr-only">
                    Nom
                    </label>
                    <input
                        type="text"
                        name="Nom"
                        id="nom"
                        autoComplete="name"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border-gray-300 rounded-md"
                        placeholder="Nom"
                        required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="nom" className="sr-only">
                    Code postal
                    </label>
                    <input
                    type="text"
                    name="Code Postal"
                    id="cp"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border-gray-300 rounded-md"
                    placeholder="Code Postal"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="telephone" className="sr-only">
                    Téléphone
                    </label>
                    <input
                    type="text"
                    name="Téléphone"
                    id="telephone"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border-gray-300 rounded-md"
                    placeholder="Téléphone"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="email" className="sr-only">
                    Email
                    </label>
                    <input
                    id="email"
                    name="Email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border-gray-300 rounded-md"
                    placeholder="Email"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="message" className="sr-only">
                    Message
                    </label>
                    <textarea
                    id="message"
                    name="Message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border border-gray-300 rounded-md"
                    placeholder="Votre besoin"
                    defaultValue={''}
                    required 
                    />
                </div>
                <div className="hidden">
                    <label htmlFor="tellepro" className="sr-only">
                    Téllépro
                    </label>
                    <input
                        type="text"
                        name="tellepro"
                    />
                    <input
                        type="text"
                        name="Source"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Source"
                        value="blicko.fr"
                    />
                    <input
                        type="text"
                        name="Moyen"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Moyen"
                        value={props.moyen}
                    />
                    <input
                        type="text"
                        name="Formulaire"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Formulaire"
                        value={props.moyen}
                    />
                </div>
                <div className="pb-4 text-left">
                    <input 
                        type="checkbox" 
                        id="cgu" 
                        name="CGU"
                        className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-600 focus:border-orange-600 border-gray-300 rounded-md" 
                        required
                    />J'accepte les <a href="/nous-connaitre/legal/cgu/" className="text-orange-600" target="_blank" rel="noreferrer">CGU</a>
                </div>
                <div className="pb-4">
                    <button
                    type="submit"
                    className="inline-flex items-center mr-6 rounded-md ring-1 bg-orange-600 hover:bg-orange-700 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                    {loading ? "Envoi en cours ..." : "Envoyer"}
                    </button>
                </div>
                </form>
        </div>
    </div>
  );
}

export default Form