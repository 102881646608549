import React from "react"

//import { Disclosure } from '@headlessui/react'
import { ScaleIcon, ThumbUpIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/outline'
import TrustpilotLogo from '../../images/logo-trustpilot-black.svg';
import Trustpilotblicko from '../../images/trustpilot.png';
import Googleblicko from '../../images/google_avis.png';


/*
const navigation = [
    { name: 'Sommaire', href: '#', current: true },
    {
      name: 'Nos meilleurs plombiers à Albigny-sur-Saône',
      current: false,
      children: [
      ],
    },
    {
      name: 'blicko, votre solution plomberie à Albigny-sur-Saône (on aime!)',
      current: false,
      children: [
      ],
    },
    {
      name: 'Plomberie en panne : nous vous dépannons à Albigny-sur-Saône',
      current: false,
      children: [
        { name: 'Chauffe eau en panne à Albigny-sur-Saône : il fonctionne mais disjoncte', href: '#' },
        { name: 'Radiateurs emboués Albigny-sur-Saône : problème de chauffage urgent', href: '#' },
      ],
    },
    {
      name: 'Solliciter un plombier de chez blicko à Albigny-sur-Saône',
      current: false,
      children: [
      ],
    },
  ]


*/
/*


<div className="flex flex-col flex-grow pt-8 pb-4 bg-white overflow-y-auto">
                        
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
                            {navigation.map((item) =>
                                !item.children ? (
                                <div key={item.name}>
                                    <a
                                    href={item.href}
                                    className={classNames(
                                        item.current
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                    >
                                    {item.name}
                                    </a>
                                </div>
                                ) : (
                                <Disclosure as="div" key={item.name} className="space-y-1">
                                    {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                        className={classNames(
                                            item.current
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-[#3333e6]'
                                        )}
                                        >
                                        <svg
                                            className={classNames(
                                            open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                            'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                            )}
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                        </svg>
                                        {item.name}
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="space-y-1">
                                        {item.children.map((subItem) => (
                                            <Disclosure.Button
                                            key={subItem.name}
                                            as="a"
                                            href={subItem.href}
                                            className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                                            >
                                            {subItem.name}
                                            </Disclosure.Button>
                                        ))}
                                        </Disclosure.Panel>
                                    </>
                                    )}
                                </Disclosure>
                                )
                            )}
                            </nav>
                        </div>
                    </div>

*/

/*

<p className="mt-4 text-lg leading-6 text-blue-200">
                        ou<br /><br />
                        <a
                        href="https://app.blicko.fr/login"
                        target="_blank"
                        className="underline"
                        rel="noreferrer"
                        >
                        Diagnostic en ligne immédiat<br />
                        (gratuit et sans engagement)
                        </a>
                        </p>



                        <p className="mt-4 text-2xl leading-6 text-white">
                          <a href="tel:0974371440"><span className="font-bold">09 74 37 14 40</span></a>
                        </p>

*/

  const features = [
    {
      name: '1er réseau national français',
      icon: ThumbUpIcon,
    },
    {
      name: 'Disponible 7j/7',
      icon: UserGroupIcon,
    },
    {
      name: "Prix ferme et fixé à l'avance",
      icon: ScaleIcon,
    },
    {
      name: 'Gestes de premiers secours',
      icon: ShieldCheckIcon,
    },
  ]

export default function KdpHeader() {
  return (

            <div className="bg-white ">

                    {/* <div className="mt-12 flex flex-col flex-grow bg-[#3333e6] rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h2 className="text-1xl font-extrabold text-white sm:text-2xl">
                        <span className="block">Plomberie sanitaire ou chauffage</span>
                        </h2>
                        <p className="mt-4 text-m leading-6 text-blue-200">
                        Trouvez facilement un plombier de confiance. Réactivité, qualité et efficacité garanties.
                        </p>
                        <a
                        href="/devis/contact/"
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 sm:w-auto"
                        >
                        Demandez un dépannage
                        </a>
                        <p className="mt-4 py-2 text-xl leading-6 text-white lg:hidden">
                          <a href="tel:0428295016"><span className="font-bold">04 28 29 50 16</span></a>
                        </p>
                    </div> */}

                    <div className="mt-12 bg-white">
                      <div className="max-w-7xl">
                        <a href="https://fr.trustpilot.com/review/blicko.fr" target="_blank" rel="noreferrer">
                          <div>
                            <img className="h-28" src={Trustpilotblicko} alt="Trustpilot Logo" />
                          </div>
                          <div className="mt-1">
                            <img className="h-6" src={TrustpilotLogo} alt="Trustpilot Logo" />
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className=" bg-white">
                      <div className="max-w-7xl">
                        <a href="https://www.google.com/search?q=blicko&source=lmns&bih=1754&biw=3112&hl=fr&sa=X&ved=2ahUKEwijlIPctOn4AhUKdRoKHYkFCdMQ_AUoAHoECAEQAA#lrd=0x47f4ea588d7f2751:0x2aac72f159af8b9c,1,,," target="_blank" rel="noreferrer">
                          <div className="mt-10">
                            <img className="h-28" src={Googleblicko} alt="Google Logo" />
                          </div>
                        </a>
                      </div>
                    </div>



                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Pourquoi choisir blicko ?
                          </h3>
                        </div>

                        <div className="mt-10">
                          <dl className="space-y-10 md:space-y-0 md:grid md:gap-x-8 md:gap-y-10">
                            {features.map((feature) => (
                              <div key={feature.name} className="relative">
                                <dt>
                                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#3333e6] text-white">
                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900  py-2">{feature.name}</p>
                                </dt>
                              </div>
                            ))}
                          </dl>
                        </div>
                      </div>
                    </div>

                    

                    
            </div>



        )
    }